<template>
  <address-form type="LEGAL" />
</template>
<script>
import AddressForm from "@/views/steps/AddressForm";
export default {
  name: 'LegalAddress',
  components: {
    AddressForm
  }
}
</script>
